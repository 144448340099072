<template>
    <div class="terms-of-service">
      <div class="header">
        <router-link :to="{ name: 'chat' }" class="home-link">
          <img src="/static/unstruct-logo-new.png" alt="UnStruct Logo" width="55" height="55" />
        </router-link>
        <h1>UnStruct Terms of Service</h1>
      </div>
      <p class="last-updated">Last Updated: October 30, 2024</p>

      <p>Welcome to the Terms of Service (these "Terms") for UnStructAI, Inc ("Company", "we" or "us"). These Terms govern your access to and use of our website, www.unstruct.ai, and any related services (collectively, the "Services").</p>

      <p><strong>PLEASE READ THESE TERMS CAREFULLY.</strong> By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, do not use the Services.</p>

      <h2>1. Services and Eligibility</h2>

      <h3>1.1 User Inputs and AI-Generated Outputs:</h3>

      <p>a) UnStruct.AI's core functionality revolves around your ability to upload, input, or otherwise provide various forms of data and information ("User Inputs") to our AI-powered platform. Our advanced AI algorithms then process these User Inputs to generate insights, analyses, or other forms of information ("AI Outputs"). Please note that the use of our platform, including the AI Outputs, may be subject to certain third-party license terms where applicable.</p>

      <p>b) Responsibility for User Inputs: By providing User Inputs to UnStruct.AI, you affirm that you have all necessary rights and permissions to do so. This includes any intellectual property rights, contractual obligations, or other legal considerations associated with your User Inputs. You are solely responsible for ensuring that your use of UnStruct.AI, including the submission of User Inputs and generation of AI Outputs, complies with all applicable laws and does not infringe on any third-party rights.</p>

      <p>c) Prohibited Uses: You agree not to use UnStruct.AI to:</p>
      <ul>
        <li>(i) Generate AI Outputs that violate any laws, infringe on intellectual property rights, or breach any contractual obligations.</li>
        <li>(ii) Process User Inputs that you do not have the legal right to use or share.</li>
      </ul>

      <p>d) AI Output Attribution: When sharing or publishing AI Outputs generated by UnStruct.AI, you agree to:</p>
      <ul>
        <li>(i) Clearly attribute UnStruct.AI as the source of the AI Outputs.</li>
        <li>(ii) Not misrepresent the nature of the AI Outputs or conceal the fact that they were generated by artificial intelligence.</li>
      </ul>

      <h3>1.2 Eligibility:</h3>
      <p>You must be at least 13 years of age to use the Services. If you are at least 13 but under the age of majority in your jurisdiction, you may only use the Services with the consent and supervision of a parent or legal guardian who agrees to be bound by these Terms. By using the Services, you represent and warrant that you meet these requirements.</p>

      <h2>2. User Accounts and Responsibilities</h2>

      <h3>2.1 Account Creation:</h3>
      <p>To use certain features of the Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.</p>

      <h3>2.2 User Conduct:</h3>
      <p>You agree not to use the Services for any unlawful purpose or in any way that interrupts, damages, or impairs the Services.</p>

      <h3>2.3 Anonymous Usage:</h3>
      <p>UnStruct.AI offers anonymous access to all features, including our Pro services. When using our service anonymously:</p>
      <ul>
      <li>Your access is controlled by a unique key that:</li>
      <ul>
        <li>Is stored in your browser's local storage</li>
        <li>Can be saved and reused across devices</li>
        <li>Functions like a password for your anonymous account</li>
      </ul>
      <li>This key is essential for:</li>
      <ul>
        <li>Accessing your account and Pro features</li>
        <li>Maintaining your subscription</li>
        <li>Accessing your usage history</li>
      </ul>
      <li>If you lose access to your key:</li>
      <ul>
        <li>You can restore access by adding the saved key to any browser's local storage</li>
        <li>If key is permanently lost, you will lose access to your account and subscription</li>
        <li>Contact support@unstruct.ai with your payment details to cancel subscription if needed</li>
      </ul>
      <li>For automatic cross-device sync, consider creating a registered account</li>
      </ul>

      <h2>3. Intellectual Property Rights</h2>

      <h3>3.1 Ownership:</h3>
      <p>The Services and their original content, features, and functionality are owned by UnStruct.AI and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>

      <h3>3.2 User Content:</h3>
      <p>You retain ownership of any content you submit to the Services. By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, modify, and distribute that content for the purpose of operating and improving the Services.</p>

      <h2>4. Privacy and Data Usage</h2>

      <h3>4.1 Privacy Policy:</h3>
      <p>Our Privacy Policy, available at UnStruct AI Privacy Policy, describes how we handle the information you provide to us when you use the Services.</p>

      <h2>5. AI-Generated Outputs and Disclaimers</h2>

      <h3>5.1 AI-Generated Content:</h3>
      <p>Our Services may include artificial intelligence tools that generate responses or outputs based on user inputs ("AI Outputs").</p>

      <h3>5.2 No Professional Advice:</h3>
      <p>The Outputs generated by our AI tools are for informational purposes only. They do not constitute professional, legal, financial, medical, or any other form of expert advice. You should not rely solely on these Outputs for making important decisions.</p>

      <h3>5.3 Accuracy and Reliability:</h3>
      <p>While we strive to provide useful and accurate information, we cannot guarantee the accuracy, completeness, or reliability of any Outputs generated by our AI tools. The Outputs may contain errors, biases, or outdated information.</p>

      <h3>5.4 User Responsibility:</h3>
      <p>You are solely responsible for evaluating and verifying any information or suggestions provided in the Outputs. We strongly recommend consulting with qualified professionals for advice in their respective fields.</p>

      <h3>5.5 No Liability:</h3>
      <p>UnStruct.AI shall not be held liable for any actions taken or decisions made based on the Outputs generated by our AI tools. By using these features, you acknowledge and accept these limitations and risks.</p>

      <h2>6. User Rights and Restrictions</h2>

      <h3>6.1 Right to Use Services:</h3>
      <p>UnStruct.AI grants you a limited, non-exclusive, non-transferable, and revocable right to access and use our Services for your personal, non-commercial purposes, provided you comply with these Terms. This includes the right to use our AI-powered platform to process User Inputs and generate AI Outputs as described in Section 1.1.</p>

      <h3>6.2 Restrictions on Your Use of the Services:</h3>
      <p>When using our Services, you agree not to:</p>
      <ol type="a">
        <li>Modify, adapt, or hack the Services or modify any other website to falsely imply that it is associated with UnStruct.AI.</li>
        <li>Use the Services in any unlawful manner or for any illegal purpose.</li>
        <li>Attempt to access any other user's account or any non-public portions of the Services.</li>
        <li>Use any automated system, including "robots," "spiders," or "offline readers," to access the Services in a manner that sends more request messages to our servers than a human can reasonably produce in the same period of time.</li>
        <li>Transmit any viruses, malware, or other types of malicious software, or links to such software, through the Services.</li>
        <li>Use the Services to infringe the intellectual property rights of others, or to commit an unlawful activity.</li>
        <li>Reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code for the Services.</li>
        <li>Interfere with, or disrupt, the access of any user, host or network, including sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of content in such a manner as to interfere with or create an undue burden on the Services.</li>
        <li>Impersonate or misrepresent your affiliation with any person or entity.</li>
        <li>Encourage or enable any other individual to do any of the foregoing.</li>
      </ol>
      <p>UnStruct.AI reserves the right to investigate and take appropriate legal action against anyone who, in UnStruct.AI's sole discretion, violates this provision, including without limitation, removing the offending content from the Services, suspending or terminating the account of such violators, and reporting the violator to law enforcement authorities.</p>

      <h2>7. Payments and Subscriptions</h2>

      <h3>7.1 Fees:</h3>
      <p>Certain aspects of the Services may be provided for a fee. You agree to pay all applicable fees.</p>

      <h3>7.2 Subscription Renewals and Cancellations:</h3>
      <p>By purchasing a subscription, you agree that it will automatically renew based on the frequency stated on your subscription page (defaulting to monthly if not specified). Renewals will be at the then-current rates, and your payment method will be automatically charged at the beginning of each new subscription period for all applicable fees and taxes. To prevent future charges, you must cancel your subscription before the renewal date. You can do this through your Account settings or by contacting support@unstruct.ai.</p>
      <p>For anonymous users, subscription management is available through your active session. If you lose access to your anonymous key, contact support@unstruct.ai with your payment details to manage your subscription.</p>
      <h3>7.3 Refund Policy:</h3>
      <p>Unless otherwise stated in these Terms, subscription fees are non-refundable. We do not provide refunds or credits for partial subscription periods. If you cancel your subscription, you will continue to have access to the paid Services until the end of your current subscription period.</p>

      <h3>7.4 Anonymous Subscriptions:</h3>
      <p>For anonymous users subscribing to our Pro features:</p>
      <ul>
        <li>Payment processing is handled securely through Stripe</li>
        <li>No personal information is required beyond payment details</li>
        <li>Subscription is tied to your anonymous key</li>
        <li>Your anonymous key is your access credential - you can:</li>
        <ul>
          <li>Store it securely like a password</li>
          <li>Add it back to any browser's local storage to regain access</li>
          <li>Use it across different devices</li>
        </ul>
        <li>Cancel anytime with one click from within the app</li>
        <li>If you've lost access to your key and need to cancel:</li>
        <ul>
          <li>Email support@unstruct.ai with your Stripe payment details</li>
          <li>Or contact Stripe directly with your payment information</li>
        </ul>
        <li>The refund policy in Section 7.3 applies equally to anonymous subscriptions:</li>
        <ul>
          <li>No refunds for lost access due to lost/deleted anonymous keys</li>
          <li>No refunds for partial subscription periods</li>
          <li>Service remains accessible until end of current billing period</li>
        </ul>
      </ul>

      <h2>8. Disclaimers and Limitations of Liability</h2>

      <h3>8.1 Disclaimer of Warranties:</h3>
      <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.</p>

      <h3>8.2 Limitation of Liability:</h3>
      <p>TO THE FULLEST EXTENT PERMITTED BY LAW, UNSTRUCT.AI SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES. UNSTRUCT.AI'S TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID UNSTRUCT.AI, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM.</p>
      <p>For anonymous users, you acknowledge and accept that loss of your browser-stored key will result in loss of access to your account and subscription. UnStruct.AI is not liable for any loss of access or data resulting from lost, deleted, or compromised anonymous keys.</p>
      <p>For anonymous users, you explicitly acknowledge that:</p>
      <ul>
        <li>Your anonymous key is your sole means of account access</li>
        <li>We cannot verify ownership of anonymous accounts</li>
        <li>Loss of key means loss of access and subscription benefits</li>
      </ul>

      <h2>9. Termination</h2>

      <p>We may terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason, including if you breach these Terms.</p>

      <h2>10. Changes to Terms</h2>

      <p>We may modify these Terms at any time. We will provide notice of any significant changes to these Terms.</p>

      <h2>11. Governing Law and Dispute Resolution</h2>

      <h3>11.1 Governing Law:</h3>
      <p>These Terms shall be governed by the laws of the state of California, without regard to its conflict of law provisions.</p>

      <h3>11.2 Arbitration Agreement:</h3>
      <p>Please read this section carefully. It affects your rights and how claims between you and UnStruct.AI are resolved.</p>

      <p>a) Binding Arbitration: You and UnStruct.AI agree that any dispute, claim, or controversy arising out of or relating to these Terms or the use of the Services will be settled by binding arbitration, except that each party retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights.</p>

      <p>b) Arbitration Procedures: The arbitration will be administered by the American Arbitration Association ("AAA") in accordance with the AAA's Consumer Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes then in effect.</p>

      <p>c) Class Action Waiver: YOU AND UNSTRUCT.AI AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</p>

      <p>d) Opt-out: You have the right to opt-out and not be bound by the arbitration and class action waiver provisions by sending written notice of your decision to opt-out to support@unstruct.ai within 30 days of your first use of the Services.</p>

      <h3>11.3 Small Claims Court:</h3>
      <p>Notwithstanding the foregoing, either you or UnStruct.AI may bring an individual action in small claims court.</p>

      <h3>11.4 Time Limitation:</h3>
      <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

      <h2>12. Contact Us</h2>

      <p>If you have any questions about these Terms, please contact us at support@unstruct.ai.</p>

      <p>By using UnStruct.AI, you agree to these Terms of Service.</p>
    </div>
  </template>

  <script>
  export default {
    name: 'TermsOfService',
  }
  </script>

  <style scoped>
  .terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .home-link {
    text-decoration: none;
    margin-right: 20px;
  }

  h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin: 0;
  }

  h2 {
    font-size: 1.8em;
    margin-top: 30px;
  }

  h3 {
    font-size: 1.4em;
    margin-top: 20px;
  }

  p, ul, ol {
    margin-bottom: 15px;
  }

  ul, ol {
    padding-left: 30px;
  }

  .last-updated {
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 20px;
  }

  a {
    color: #3498db;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }
  </style>
